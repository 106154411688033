/** @jsx jsx */
import { jsx } from "theme-ui";
import Content from "@src/templates/content";
import ImageTextBlock from "@src/components/ImageTextBlock";
import Testimonial from "@src/components/Testimonial";
import Container from "@src/components/Container";
import CTABanner from "@src/components/CTABanner";
import InternalLink from "@src/components/InternalLink";
import { Link } from "gatsby";
export default ({ location }) => (
  <Content pageTitle="Comics mentoring" location={location}>
    <div>
      <Container>
        <div>
          <ImageTextBlock
            alt="Bernard sketching"
            src="/images/bernard-mentor.jpg"
          />
          <ImageTextBlock
            body={
              <div>
                <p>
                  If you are already advanced with your comics-making, Bernard
                  can cast a knowledgeable outside eye on a comic book project
                  in progress and offer editorial feedback on your work at any
                  stage during the development process. These sessions can be
                  delivered via zoom or other online platforms.
                </p>
                <p>
                  Bernard is experienced in discussions about comics projects
                  from the ground up, including:
                </p>

                <ul>
                  <li>
                    — Discussion of the concept and scoping of the project
                  </li>
                  <li>— World-building and story arcs</li>
                  <li>
                    — Consultation on the structure and pacing of the piece
                  </li>
                  <li>— Help with the shaping of scenes</li>
                  <li>— Layout of pages</li>
                  <li>— Editorial and proofreading</li>
                </ul>
              </div>
            }
          />
        </div>
      </Container>

      <Testimonial
        text="Several months ago I engaged the intimidating intellect of Bernard Caleo to cast
        his incisive eye over an early draft of my recent book, NIXIN. I needed
        someone’s help to sort out this manuscript, however I feared that the book’s
        massive scale and the disorderly state of my work-in-progress would put
        prospective editors off. This was never going to be the case with Bernard. His
        attention to detail was focussed, clear, and honest, but also empathetic and
        constructive. There were a number of face to face meetings to unpack the pros
        and cons of the manuscript, during which Bernard was able to open up my work
        with forensic acumen, making conceptual connections that I was unable to see
        myself. It’s clear that Bernard respects the process, both from the point-of-view
        of a reader and also of a maker. And from this standpoint I felt I got tremendous
        value for money. He’s a smart bikkie."
        author="- Jamie Clennett, <br/> author/artist of the graphic novel The Diemenois (2015) and the
        upcoming NIXIN"
      />

      <CTABanner
        lines={[
          <div>
            <Link to="/contact">Contact Bernard</Link> for more information and
            to book a mentoring session
          </div>,
        ]}
      />
      <Container>
        <InternalLink text="Comics tutoring" url="/comics-tutoring" />
      </Container>
    </div>
  </Content>
);
